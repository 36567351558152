export default (httpClient) => {
  return {
    abstract(location) {
      delete location.text
      return httpClient.get('v3/location/text', location)
    },

    getCities(stateIso, countryIso) {
      return httpClient.get('v3/location/cities', { state: stateIso, country: countryIso })
    },

    getStates(countryIso) {
      return httpClient.get('v3/location/states', { country: countryIso })
    },

    getCountries(lang) {
      return httpClient.get('v3/location/countries', { lang: lang })
    },
  }
}