<template>
  <div class="UiPickerCountry">
    <label v-if="!hiddenLabel">{{ $t('UiPickerCountry.label.country') }}</label>

    <UiPicker
      :label="$t('UiPickerCountry.label')"
      :options="countries"
      optionText="langName"
      optionKey="iso2"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import UiPicker from "../UiPicker/UiPicker.vue";
import useApi from "@/modules/api/mixins/useApi";
import apiLocation from "../UiPickerLocation/api";

export default {
  name: "ui-picker-country",
  mixins: [useApi, useI18n],
  api: apiLocation,

  components: {
    UiPicker,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    hiddenLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countries: [],
    };
  },

  methods: {
    async getCountries() {
      this.countries = await this.$api.getCountries(this.$store.state.i18n.language);
    },
  },

  mounted() {
    this.getCountries();
  },

  i18n: {
    en: {
      "UiPickerCountry.label": "Select country",
      "UiPickerCountry.label.country": "Country",
    },

    es: {
      "UiPickerCountry.label": "Seleccionar país",
      "UiPickerCountry.label.country": "País",
    },

    de: {
      "UiPickerCountry.label": "Land auswählen",
      "UiPickerCountry.label.country": "Land",
    },
  }
};
</script>
